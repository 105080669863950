<template>
    <div>
        <div>
            <div class="col-md-8 offset-md-2 text-center mb-5" id='hero'>
                <h2>Wachtwoord wijzigen</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 offset-md-1" id='login-bar'>
                <div class="row">
                    <form @submit.prevent="submit">
                        <div class="col-md-10 offset-md-1 d-grid mt-5 mb-3" id='login-grid'>
                            <input placeholder='Nieuw wachtwoord' v-model="form.password1" id='password1' type='password' class='form-control'/>
                            <input placeholder='Herhaal wachtwoord' v-model="form.password2" id='password2' type='password' class='form-control'/>
                        </div>
                        <div class="col-md-10 offset-md-1">
                            <p v-if="showError" id="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-6 offset-md-3">
                            <button :disabled='isDisabled' class='btn btn-primary w-100 btn-lg mb-2' type="submit">Versturen <font-awesome-icon v-if="isDisabled" icon="fa-solid fa-spinner" pulse/></button>
                             <hr>
                        </div>
                        <div class="col-md-6 offset-md-3 text-center mb-5">
                          <p><small>Door jouw wachtwoord te wijzigen, ga je akkoord met de privacy voorwaarden van Skipiste Nieuwegein.</small></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        Password1: '',
        Password2: ''
      },
      loading: false,
      showError: false,
      errorMessage: 'Er ging iets fout'
    }
  },
  computed: {
    isDisabled: function () {
      return this.loading
    }
  },
  methods: {
    async submit () {
      this.loading = true
      if (this.form.password1 !== this.form.password2 && this.form.password1 !== '') {
        this.errorMessage = 'Wachtwoorden komen niet overeen'
        this.showError = true
        this.loading = false
        return false
      }
      const User = {
        Password1: this.form.password1,
        Password2: this.form.password2,
        Token: this.$route.query.token,
        Email: this.$route.query.email
      }
      this.$store.dispatch('auth/resetPassword', User).then((res) => {
        if (res.status === 200) {
          this.$router.push({
            name: 'login',
            params: { bigMessage: 'Nieuw wachtwoord ingesteld' }
          })
          this.showError = false
          this.loading = false
        } else {
          this.errorMessage = res.response.data.message
          this.showError = true
          this.loading = false
        }
        return true
      }).catch(() => {
        this.errorMessage = 'Er ging iets fout'
        this.showError = true
        this.loading = false
      })
    }
  }
}
</script>

<style>
    #forgot-password{
        font-size: 0.9rem;
        text-align: center;
        padding: 4px 12px;
    }
    #hero h1{
        font-size: 5rem;
        font-weight: 600;
    }
    #login-bar{
        background: #fff;
        border-radius: 25px;
        min-height: 300px;
    }
    #login-grid{
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        grid-template-rows: auto;
    }
    @media screen and (max-width: 992px){
         #login-grid{
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
         #hero h1{
        font-size: 3rem;
        font-weight: 600;
    }
    }
</style>
